.chat-history {
    text-align: left;
    width: 100%;
    overflow-y: scroll;
    display: "flex";
    justify-content: start;
}

.chat-history .user {
    text-align: right;
    display: "flex";
    justify-content: end;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #94d1f2 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #94d1f2;
    border-radius: 10px;
    border: 3px solid #ffffff;
}

