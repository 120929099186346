/* FormPage.css */

.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.checkbox-container input[type="checkbox"] {
    margin-right: 10px;
}

.checkbox-label {
    margin-left: 10px;
}

.input-label {
    margin-bottom: 10px;
    font-weight: bold;
}

.checkbox-group {
    margin-top: 10px; /* Adjust this value to increase the gap */
    display: flex;
    flex-direction: column; /* Ensure vertical alignment */
}

/* Additional styling for form elements */
.form-group {
    margin-bottom: 20px; /* Add space between form groups */
}
